html, body {
    height: 100%;
}

body {
    background-color: #000;
    color: #ccc;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
}

* {
    box-sizing: border-box;
    outline: 0;
    padding: 0;
    margin: 0;
}

a {
    color: #ccc;
    font-weight: 600;
    text-decoration: none;
    transition: all .2s ease-in-out;
    margin-bottom: 10px;
}

a:hover {
    color: #00AAFF;
}

@media(min-width: 992px) {
    .container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 
            "logo contact"
            "intro photo"
            "footer footer";
        grid-column-gap: 80px;
        max-width: 1200px;
        align-items: center;

        &.logo {
            grid-area: logo;
            align-self: end;
        }

        &.intro {
            grid-area: intro;
        }

        &.photo {
            grid-area: photo;
        }

       & .contact {
            grid-area: contact;
            align-self: end;
            margin-bottom: 40px;

            & .contact__item {
                display: flex;
                justify-content: flex-end;

                & .contact__link {
                    display: flex;
                    align-items: center;

                    & .contact__icon {
                        margin-left: 4px;
                    }
                }
            }
        }

       & .footer {
            grid-area: footer;
            align-self: start;

            & .social__icon {
                font-size: 32px;
            }
        }
    }

    .logo {
        img {
            height: 100px;
        }
    }

    .footer {
        margin-top: 40px;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        & .copyright {
            text-align: right;
        }

    }
}

@media(min-width: 768px) {
    .container {
        padding: 40px 40px;
        margin-bottom: 40px;
    }
}

@media(max-width: 319px) {
    .title {
        line-height: 52px;
        font-size: 36px;
    }

    .description {
        line-height: 36px;
        font-size: 20px;
    }
}

@media(max-width: 991px) {
    .wrap {
        display: block;
    }
    .container {
        text-align: center;
    }

    .photo {
        margin: 40px auto;
        max-width: 66.66666%;
    }

    .logo {
        margin-bottom: 40px;

        & img {
            height: 80px;
        }
    }

    .footer {
        margin-top: 40px;
        flex-flow: column;

        & .copyright {
            margin-top: 40px;
        }
    }

    .footer .social {
        justify-content: center;

        & .social__item .social__link .social__icon {
            font-size: 24px;
        }
    }

    .footer .social:first-child {
        margin-top: 0;
    }
}

@media(max-width: 767px) {
   .container {
        max-width: 640px;
        padding: 40px 20px;
   }

}

@media (max-width: 1199px) and (min-width: 320px) {
    .title {
        line-height: calc(52px + 12 * ((100vw - 320px) / 880));
        font-size: calc(36px + 12 * ((100vw - 320px) / 880));
    }

    .description {
        line-height: calc(36px + 10 * ((100vw - 320px) / 880));
        font-size: calc(20px + 12 * ((100vw - 320px) / 880));
    }

}

@media(min-width: 1200px) {
    .description {
        line-height: 46px;
        font-size: 32px;
    }

    .title {
        font-size: 48px;
        line-height: 64px;
    }
}

.container {
    margin: auto;
    min-height: 100%;
    width: auto;
    min-width: 220px !important;
}

.contact {
    list-style: none;
    margin: 0;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

.title {
    margin-bottom: 40px;
    font-weight: 900;
    color: white;
}

.description {
    font-family: 'Merriweather', serif;
    margin-bottom: 40px;
}

.footer {
    display: flex;

    & .social {
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        & .social__item {
            margin-right: 13px;
        }

        & .social__link {
            font-weight: normal;
        }

        & .social__icon {
            font-size: 32px;
        }
    }

    & .copyright {
        font-size: 12px;
        line-height: 18px;
        color: #666;
    }

}

.photo {
    img {
        border-radius: 6% 0 6% 0;
    }
}